@tailwind base;
@tailwind components;
@tailwind utilities;

/*
Team pls add HEX color as comment before  adding HSL
*/
/* Mobile View Fix */
body {
  width: auto;
}

@media (min-width: 200px) and (max-width: 991px) {
  body {
    width: 1640px;
    overflow-x: auto;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 82 75% 40%; /* #7bb31a */
    --primary-text: 0 0% 13%; /* #222222*/
    --primary-foreground: 0 0% 44%; /*#505050 ,#6f6f6f*/

    --secondary: 82 74% 40%;
    --secondary-foreground: 0 0% 100%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: #e0e0e0;
    --input: #e0e0e0;
    --placeholder: #b9b9b9;
    --placeholder-text: #b9b9b9;
    --ring: 222.2 84% 4.9%;
    --gray-btn: #ebebeb;

    --gray-text: #707070;
    --pricing-text: #2c2d31;
    --greyish-brown: #585858;
    --radius: 0.7rem;
    --light-black: #575757;
    --light-green: #fbfff5;
    --shade-green: #f7ffea;
    --modal-header: #fafafa;
    --light-gray: #f7f7f7;
    --required-color: #e23636;
    --footer-color: #f5f5f5;
    --light-orange: #fffcf5;
    --orange: #df7e30;
    --half-white: #fcfcfc;
    --white: #ffffff;
    --light-grey: #f0f0f0;
    --pale-gold: #ffd166;
    --badge-border: #cfcfcf;
    --green-border: #70a800;
    --chip-border: #d1d507;
    --chip-bg: #fffff0;
    --orange-tag: #f7ae00;
    --sickly-green: #7cb519;
    --gray98: #fafafa;
    --bright-orange: #df7d2f;
    --grey-forty: #666666;
    --black: #000;
    --deep-black: #000000;
    --text-dark: #272727;
    --dark-text: #222222;
    --pale-gray: #efefef;
    --orange-card: #fff5ed;
    --sponsor-color: #fab702;
    --priority-bg: #e7fdd6;
    --priority-border: #d2f7b6;
    --expand-icon: #bababa;
    --gray-border: #f7f7f7;
    --hightlight-tag: #7bb31a;
    --hightlight-bg: #7bb31a;
    --table-head-color: #f4f4f4;
    --light-ash: #b1b1b1;
    --lightblack-text: #4a4a4a;
    --bright-red: #f52424;
    --delete-btn-color: #ef3737;
    --light-title: #747474;
    --orange-border: #fffdf9;
    --lightgreen-border: #6ea6002e;
    --lightorange-border: #f19d002e;
    --inputTextPlaceholder: #878079;
    --golden-divider: #fff0e5;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 82 75% 40%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 82 74% 40%;
    --secondary-foreground: 0 0% 100%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: #e0e0e0; /*#e0e0e0*/
    --placeholder: #b9b9b9;
    --ring: 212.7 26.8% 83.9%;
    --light-black: 0 0 34%;
    --dim-gray: 0 0 45%;
  }

  @font-face {
    font-family: 'nunitosans_italic';
    src: url('../public/assets/fonts/NunitoSans-Italic.ttf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'nunitosans';
    src: url('../public/assets/fonts/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf')
      format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'ns_bold';
    src: url('../public/assets/fonts/NunitoSans-Bold.ttf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'ns_regular';
    src: url('../public/assets/fonts/NunitoSans-Regular.ttf') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'ns_semibold';
    src: url('../public/assets/fonts/NunitoSans-SemiBold.ttf')
      format('opentype');
    font-display: swap;
  }

  * {
    font-family: 'nunitosans';
    box-sizing: 'borderBox';
    /* @apply border-border; */
  }
  body {
    @apply bg-background text-foreground;
  }
}
@layer components {
  .pricing-card {
    @apply flex flex-col bg-light_gray border border-light_gray rounded items-center  cursor-pointer;
  }

  .selected-price-card {
    @apply bg-light_green border border-secondary;
  }
  .top-nav-bar {
    @apply hover:bg-[#fad26e] hover:border-solid hover:border-[#fad26e] p-1 px-2 rounded-sm;
  }
}
@layer utilities {
  .scrollbar-custom {
    scrollbar-width: thin;
    scrollbar-color: #f19d002e #f19d002e;
  }

  .scrollbar-custom::-webkit-scrollbar {
    width: 5px;
  }

  .scrollbar-custom::-webkit-scrollbar-track {
    background: #f19d002e;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #f19d002e;
    border-radius: 10px;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background: #f19d002e;
  }
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
} */

::-webkit-scrollbar {
  display: none;
}

/* .demo-background-image {
  background-image: url(../public/LoginOrSignUp.webp);
} */

.demo-background-image {
  background-image: url(../public/LoginOrSignUp.webp);
}

.signup-web-bg-image {
  background-image: url(../public/assets/image/signup-web-img.png);
}

.signup-background {
  background-image: url('https://ubqfructidorprod.blob.core.windows.net/prod-fructidor/website_static_images/login_background.png');
}

.required:after {
  content: ' *';
  color: red;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border:
    0px,
    1px,
    0px,
    1px solid #dddddd;
  border-right: 1px solid #dddddd;
  /* border-left:  1px solid #dddddd; */
  padding: 8px;
}

th:last-child {
  border-right: none !important;
}

td:last-child {
  border-right: none !important;
}

.grey-border {
  background-color: #f8f8f8;
  border-bottom: 1px solid #dddddd;
}

th {
  padding-top: 41px !important;
  padding-bottom: 32px !important;
  vertical-align: baseline;
}

.borderBottom {
  border-bottom: 1px solid #dddddd;
}

td {
  max-width: 295px;
}

.highlighted-column {
  background-color: red;
}

.DropDownSelect .MuiInputBase-root {
  padding: 14px !important;
}

.TextField .MuiOutlinedInput-root {
  padding: 0px !important;
  /* border: none; */
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
  height: 40px;
}

.MuiOutlinedInput-root {
  /* height: 40px; */
  padding: 0px !important;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-size: 13px !important;
  padding: 0 0.6rem !important;
  color: #222222;
}
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid #e0e0e0 !important;
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline:hover {
  border: 2px solid #e0e0e0;
}
.MuiAutocomplete-input::placeholder {
  font-family: 'nunitosans';
  font-size: 14px !important;
  font-weight: 400;
}

.MuiAutocomplete-noOptions {
  font-family: 'nunitosans' !important;
  font-size: 14px;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'nunitosans';
  font-size: '14px' !important;
}
.css-6wwd73-MuiAutocomplete-noOptions {
  padding: 10px 10px !important;
}
.MuiAutocomplete-option {
  font-size: '14px !important';
}
.dot.active {
  background-color: #7bb31a;
}

.font-10 {
  font-size: 10px;
}
.font-weight-600 {
  font-weight: 600;
}

.showAllHeight {
  max-height: 120rem;
}

.progress {
  width: 6vw;
  > svg {
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
    .backg {
      fill: lightgray;
    }
    .fill {
      transition: width 0.5s;
      fill: url(#YellowGradient);
    }
  }
}

.stop1 {
  stop-color: goldenrod;
}
.stop2 {
  stop-color: orange;
}

.navActive {
  padding: 0.2rem 0.7rem;
  background-color: #fad26e;
  border: 1px solid #fad26e;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.product_form .MuiInputBase-root {
  display: inline-table !important;
}

.dialogContent {
  padding: 0;
}

.selectTrigger {
  width: fit-content !important;
}

.select-placeholder:placeholder {
  color: #b9b9b9;
}
.searchList li:hover,
.searchList li:active,
.searchList .Mui-focused {
  color: #7bb31a;
  font-size: 14px;
  font-weight: 500;
  background-color: #f7ffea !important;
}

.searchList li {
  font-size: 14px;
  font-weight: 500;
}

.next-line {
  word-wrap: break-word;
}

.ow {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.checked-color .text-secondary {
  color: #df7d2f !important;
  fill: #df7d2f !important ;
}

.font_22 {
  font-size: 22px;
}

.general-table {
  width: 100%;
  display: block;
}

.general-table .service-ul {
  font-size: 14px;
}

.general-table .service-ul .service-th,
.general-table .service-ul .service-td {
  padding: 0.6rem 1rem;
  border: 1.5px solid #fff;
}

.general-table .service-ul .service-th {
  font-weight: 500;
}

.general-table .service-ul .service-td {
  max-width: none;
  font-weight: 300;
  color: #6f6f6f;
}

.circle {
  display: flex;
  height: 14px;
  width: 14px;
  background-color: #df7d2f;
  border-radius: 50%;
  justify-content: center;
  align-items: baseline;
}

.custom-checkbox {
  appearance: none;
  background-color: #fff;
  border: 1px solid #000;
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.custom-checkbox:checked {
  background-color: hsl(var(--secondary));
  border: 1px solid hsl(var(--secondary));
}
.custom-checkbox:checked:after {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.headerSearch .MuiAutocomplete-noOptions {
  display: none;
}

.text-white {
  color: #fff;
}

.custom-font-class {
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  padding: 0.2rem 0.4rem;
  padding-bottom: 0rem;
  background-color: rgb(71, 69, 69);
  color: #fff;
  text-align: center;
  border-radius: 3px;
  position: absolute;
  z-index: 20;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  height: 3rem;
  width: max-content;
  text-wrap: wrap;
  font-size: 12px;
  width: 150%;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: -2px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(71, 69, 69) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (min-width: 0px) and (max-width: 767px) {
  .login-modal-content {
    width: 400px;
  }

  .language-dropdown img {
    width: 30px;
    height: auto;
  }
  .language-dropdown .arrowIcon {
    width: 10px;
    height: auto;
  }
  .language-dropdown .language-text {
    display: none;
  }

  .language-dropdown ul {
    min-width: 140px;
    margin-left: -50px;
  }

  .nav-search-bar {
    display: flex;
    justify-content: center;
    position: relative;
    top: 0px;
    margin-bottom: -60px;
  }
  .nav-search-bar .flex {
    border: 1px solid #ccc;
  }
  .nav-scroll-item {
    position: relative;
    top: 80px;
    overflow-x: scroll;
    text-wrap: nowrap;
  }
  .nav-scroll-item .top-nav-bar,
  .nav-scroll-item .navActive {
    text-wrap: nowrap;
  }
}

.sidefilter-search .MuiAutocomplete-root {
  padding: 0px 0px;
}
.sidefilter-search .sidefilter-search {
  padding: 0px 0px;
}

/*Scroll bar*/

.scrollbar-thin::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #f0f0f0;
  width: 5px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 10px;
}

/* For Firefox */
.scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #f0f0f0;
}

.company-list-ad img {
  margin-top: 0.5rem;
}

.form-loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 15deg, #0000, #000);
  animation: l26 1s infinite steps(12);
}
.form-loader,
.form-loader:before,
.form-loader:after {
  background:
    radial-gradient(closest-side at 50% 12.5%, #df7e30 96%, #0000) 50% 0/20% 80%
      repeat-y,
    radial-gradient(closest-side at 12.5% 50%, #df7d2f 96%, #0000) 0 50%/80% 20%
      repeat-x;
}
.form-loader:before,
.form-loader:after {
  content: '';
  grid-area: 1/1;
  transform: rotate(30deg);
}
.form-loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {
    transform: rotate(1turn);
  }
}

.custom-popper div ul li {
  word-wrap: break-word !important;
  display: block !important;
  font-size: 14px;
}

/* CHART */

.recharts-cartesian-grid-horizontal,
.recharts-cartesian-grid-vertical {
  display: none;
}
.recharts-text tspan {
  fill: #92959e !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}
.recharts-legend-wrapper {
  display: none;
}
.recharts-cartesian-axis-tick-line {
  stroke: #979797 !important;
}
.recharts-cartesian-axis-line {
  stroke: #979797 !important;
}
.recharts-tooltip-wrapper {
  border: none !important;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 12px;
}
.recharts-cartesian-axis-tick tspan {
  fill: #92959e !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}
.MuiChartsLegend-series text {
  font-size: 14px !important;
  color: #b1b1b1 !important;
  font-weight: 600 !important;
  fill: #000000 !important;
  margin-left: 15px !important;
}
.MuiChartsLegend-mark {
  border-radius: 50px !important;
}
.MuiChartsLegend-series rect {
  border-radius: 50px !important;
}

.MuiChartsLegend-column {
  display: none;
}

.customLegend {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-top: 10px;
  margin-right: 200;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.color-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
}

.grecaptcha-badge {
  display: none !important;
}

.rounded-corner {
  border-radius: 25px;
}

.modal-hidden {
  display: none !important;
}

.signup-input {
  height: 45px !important;
  font-size: 14px !important;
}

.phone-input .MuiInputBase-root::before {
  border-bottom: none;
}

.phone-input .MuiInputBase-root::after {
  border-bottom: none;
}

.phone-input .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: none;
}
.newsletter-input button {
  box-shadow: none !important;
}
